export const ChatActions = {
  NEW_ADMIN_MESSAGE: "NEW_ADMIN_MESSAGE",
  SET_MESSAGES: "SET_MESSAGES",
  SET_CONVERSATION_ID: "SET_CONVERSATION_ID",
  SET_VISITOR: "SET_VISITOR",
  SET_CONVERSATIONS_LIST: "SET_CONVERSATIONS_LIST",
  ADD_NEW_CONVERSATION: "ADD_NEW_CONVERSATION",
  SELECT_CONVERSATION: "SELECT_CONVERSATION",
  SEEN_MESSAGE: "CA_SEEN_MESSAGE",
};
