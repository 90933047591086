export const metaData = (title, desc) => {
  document.title = `${title} | Chelsford`;
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", desc);

  return () => {
    document.title = title; // Reset the title on unmount if needed
  };
};
